var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100vh","flat":""}},[_c('v-card-title',{staticClass:"xflex xflex-row xjustify-end xitems-center"},[_c('btn-tooltip',{attrs:{"tip":"Broadcast Message","color":"primary","outlined":""},on:{"click":function($event){_vm.openModal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bullhorn-outline")]),_vm._v(" Send Message ")],1)],1),_c('v-card-text',[_c('app-table',{attrs:{"headings":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'admin-workspace-info',
                  params: { id: item.id },
                })}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Info ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'admin-workspace-configs',
                  params: { id: item.id },
                })}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-cogs ")]),_vm._v(" Settings ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'admin-workspace-members',
                  params: { id: item.id },
                })}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-account-group-outline ")]),_vm._v(" Members ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'admin-workspace-bot-responses',
                  params: { id: item.id },
                })}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-robot-excited-outline ")]),_vm._v(" Bot Responses ")],1)],1)],1)],1)]}},{key:"image_url",fn:function(ref){
                var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"40"}},[_c('v-img',{attrs:{"contain":"","src":item.image_url}})],1)]}},{key:"id",fn:function(ref){
                var item = ref.item;
return [_c('clipboard',{attrs:{"text":item.id}})]}},{key:"subscription",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ucwords")(item.status))+" ")]}}])})],1),(_vm.next_page_url)?_c('v-divider'):_vm._e(),(_vm.next_page_url)?_c('v-card-actions',{staticClass:"xflex xw-full xflex-col xjustify-center xitems-center xpy-3"},[_c('btn-tooltip',{attrs:{"small":"","tip":"Load more workspaces","color":"primary"},on:{"click":_vm.fetchMoreWorkspaces}},[_vm._v(" Load More ")])],1):_vm._e(),_c('SendSlackMessageModal',{model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card min-height="100vh" flat>
    <v-card-title class="xflex xflex-row xjustify-end xitems-center">
      <btn-tooltip
        tip="Broadcast Message"
        color="primary"
        outlined
        @click="openModal = true"
      >
        <v-icon left>mdi-bullhorn-outline</v-icon>
        Send Message
      </btn-tooltip>
    </v-card-title>
    <v-card-text>
      <app-table :headings="headers" :items="items" :loading="loading">
        <template v-slot:actions="{ item }">
          <icon-list icon="mdi-dots-horizontal">
            <v-list dense>
              <v-list-item
                @click="
                  $router.push({
                    name: 'admin-workspace-info',
                    params: { id: item.id },
                  })
                "
              >
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left>
                    mdi-information-outline
                  </v-icon>
                  Info
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    name: 'admin-workspace-configs',
                    params: { id: item.id },
                  })
                "
              >
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left> mdi-cogs </v-icon>
                  Settings
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    name: 'admin-workspace-members',
                    params: { id: item.id },
                  })
                "
              >
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left>
                    mdi-account-group-outline
                  </v-icon>
                  Members
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    name: 'admin-workspace-bot-responses',
                    params: { id: item.id },
                  })
                "
              >
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left>
                    mdi-robot-excited-outline
                  </v-icon>
                  Bot Responses
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </icon-list>
        </template>
        <template v-slot:image_url="{ item }">
          <v-avatar tile size="40">
            <v-img contain :src="item.image_url" />
          </v-avatar>
        </template>
        <template v-slot:id="{ item }">
          <clipboard :text="item.id"> </clipboard>
        </template>
        <template v-slot:subscription="{ item }">
          {{ item.status | ucwords }}
        </template>
      </app-table>
    </v-card-text>
    <v-divider v-if="next_page_url"></v-divider>
    <v-card-actions
      v-if="next_page_url"
      class="xflex xw-full xflex-col xjustify-center xitems-center xpy-3"
    >
      <btn-tooltip
        @click="fetchMoreWorkspaces"
        small
        tip="Load more workspaces"
        color="primary"
      >
        Load More
      </btn-tooltip>
    </v-card-actions>

    <SendSlackMessageModal v-model="openModal"></SendSlackMessageModal>
  </v-card>
</template>

<script>
import httpBuildQuery from "http-build-query";
import SendSlackMessageModal from "@/views/GlobalComponents/Modals/SendSlackMessageModal.vue";
export default {
  data: () => ({
    openModal: false,
    headers: [
      { text: "Logo", value: "image_url", sortable: false, width: 80 },
      { text: "Team Name", value: "name", sortable: true },
      { text: "Team ID", value: "id", sortable: true },
      { text: "Domain", value: "domain", sortable: true },
      { text: "Subscription", value: "subscription", sortable: true },
      { text: "Payings", value: "payings_count", sortable: true },
      { text: "Members", value: "users_count", sortable: true },
      { value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    next_page_url: null,
    loading: false,
  }),
  created() {
    this.fetchWorkspaces();
  },
  methods: {
    fetchWorkspaces() {
      this.loading = true;
      this.$axios
        .get(
          `api/workspaces?${httpBuildQuery({
            filter: this.$route.params.filter || "subscribed",
          })}`
        )
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMoreWorkspaces() {
      this.loading = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { SendSlackMessageModal },
};
</script>

<style lang="scss" scoped></style>

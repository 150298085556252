<template>
  <app-modal
    :valid="valid"
    title="Send Slack Message"
    v-model="open"
    max-width="800"
    @confirmed="submit"
  >
    <v-card flat>
      <v-card-text>
        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Target Teams *
        </label>
        <TeamsSelect v-model="targetTeams" class="xmb-2"></TeamsSelect>

        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Recipient *
        </label>
        <v-chip-group v-model="recipient" column mandatory multiple>
          <v-chip
            label
            value="admins"
            filter
            outlined
            :color="recipient.includes('admins') ? 'primary' : ''"
          >
            Admins
          </v-chip>
          <v-chip
            label
            value="owners"
            filter
            outlined
            :color="recipient.includes('owners') ? 'primary' : ''"
          >
            Owners
          </v-chip>
          <v-chip
            label
            value="payings"
            filter
            outlined
            :color="recipient.includes('payings') ? 'primary' : ''"
          >
            Paying Members
          </v-chip>
          <v-chip
            label
            value="channels"
            filter
            outlined
            :color="recipient.includes('channels') ? 'primary' : ''"
          >
            Assigned Channel(s)
          </v-chip>
        </v-chip-group>
        <label class="xfont-bold xleading-[1em] xblock !xmy-[10px]">
          Message *
        </label>
        <app-textarea
          :rules="[requiredRule()]"
          v-model="message"
          :rows="5"
          class="mb-2"
        ></app-textarea>
        <v-alert type="warning" outlined class="xtext-[12px] xfont-bold">
          Be careful on sending broadcast messages. Testing here is not
          advisable.
        </v-alert>
      </v-card-text>
    </v-card>
  </app-modal>
</template>

<script>
import TeamsSelect from "../Forms/TeamsSelect.vue";
export default {
  props: {
    value: { type: Boolean, default: false },
    teams: { type: Array, default: () => [] },
  },
  data() {
    return {
      open: false,
      targetTeams: [],
      recipient: [],
      message: null,
    };
  },
  computed: {
    valid() {
      return this.targetTeams.length > 0 && !!this.message;
    },
    payload() {
      return {
        teams: this.targetTeams,
        message: this.message,
        recipient: this.recipient,
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.clearInputs();
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
    teams: {
      handler: function (val) {
        this.targetTeams = val || [];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    clearInputs() {
      this.message = null;
      this.targetTeams = [];
      this.recipient = [];
    },
    submit() {
      this.$axios
        .post(`admin/feedbacks`, this.payload)
        .then(({ data }) => {
          // this.appToast("New project created", "success");
        })
        .finally(() => (this.open = false));
    },
  },
  components: { TeamsSelect },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div v-bind="divAttrs">
    <v-autocomplete
      dense
      v-bind="$attrs"
      v-model="selected"
      outlined
      :loading="isLoading"
      :search-input.sync="search"
      hide-details="auto"
      item-text="name"
      item-value="id"
      multiple
      append-icon="mdi-chevron-down"
      :items="teams"
    >
      <template v-slot:item="{ item }">
        <span>
          <v-list-item>
            <v-list-item-avatar tile size="40">
              <v-img contain :src="item.image_url" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="xfont-semibold font-weight-bold">
                {{ item.name | ucwords }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.domain }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">
            Search for....
          </span>
        </v-list-item> 
      </template>
      <template v-slot:selection="{ item }">
        <v-chip color="transparent" label small class="mr-auto w-full my-1">
          <span>{{ item.name }}</span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  props: {
    divAttrs: { type: Object, default: undefined },
    value: { type: Array, default: () => [] },
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    search: {
      handler: function (val) {
        val && this.searchFor(val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isLoading: false,
      teams: [],
      search: null,
      selected: null,
      openModal: false,
    };
  },
  methods: {
    searchFor: _.debounce(function (val) {
      if (this.teams.length > 0) return;
      if (this.isLoading) return;
      this.isLoading = true;
      this.$axios
        .get(`admin/workspaces/search/${val}`)
        .then(({ data }) => {
          this.teams = data;
        })
        .finally(() => (this.isLoading = false));
    }, 100),
  },
};
</script>

<style lang="scss" scoped></style>
